<template>
  <div id="index">
    <div class="left">
      <div class="text">
        <h1 class="titleText">Finger Trading</h1>
        <h1>簡單 安全 人性化</h1>
        <h3>Plain language is writing clearly “so your users can find what they need, understand what they find, and use what they find to meet their needs.” Developed by/for the US government, but the principles of plain language apply in almost any application.</h3>
        <div class="btnBox">
          <btn-submit :width="200" color="red" @click="goLogin()">建立賬戶</btn-submit>
          <btn-submit :width="200" color="red">體驗交易享受</btn-submit>
        </div>
      </div>
    </div>
    <div class="right">
      <img src="../assets/img/phoneImg.png" alt="">
    </div>

    <total-user/>
  </div>
</template>

<script>
import TotalUser from '@/components/footer/TotalUser.vue'
import BtnSubmit from '@/components/button/BtnSubmit.vue'

export default {
  name:'Index',
  components: {
    TotalUser,
    BtnSubmit

  },
  data() {
    return {

    }
  },
  methods:{
    goLogin(){
      this.$router.push('/login')
    }
  },
}
</script>

<style scoped>
#index{
  width: 100vw;
  height: 100%;
  background: radial-gradient(#191b27,#1e1f25);
  display: flex;
}
.shadows{
  position: absolute;
  right: 18%;
  bottom: 30%;
  z-index: 10;
}
.phone{
  position: absolute;
  right: 11%;
  bottom: 30%;
  z-index: 11;
  height: 60%;
}
.left{
  position: relative;
  width: 56%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: aliceblue;
  box-sizing: border-box;
}
.right img{
  width: 600px;
  transform: rotate(35deg);
}
.right{
  width: 40%;
  height: 100%;
  color: aliceblue;
  padding-top: 100px;
}
.text h3{
  width: 800px;
}
.titleText{
  font-size: 60px;
  font-weight: 900;
}
.text{
  height: 360px;
  width: 60%;
}
.btnBox{
  display: flex;
  margin-top: 20px;
  width: 460px;
}
</style>








